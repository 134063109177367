import Swal from 'sweetalert2';
import { _x, sprintf } from '@wordpress/i18n';
import apiFetch from '@wordpress/api-fetch';

const userTab = document.querySelector('.um-account-tab-additional_users');

function checkUserNumber(maxUsers, addUserBtn) {
  const removeUserBtns = document.querySelectorAll(
      '.related-user__remove-user'
    ),
    groupCount = document.querySelectorAll('.related-user__groupfield').length;
  if (maxUsers > 0) {
    removeUserBtns.forEach((removeUserBtn) => {
      removeUserBtn.classList.remove('hidden');
    });
    if (groupCount === parseInt(maxUsers)) {
      addUserBtn.classList.add('disabled');
    } else {
      addUserBtn.classList.remove('disabled');
    }
  } else {
    addUserBtn.classList.add('disabled');
  }

  console.log(maxUsers, addUserBtn, groupCount);
}

if (userTab) {
  const usersDiv = document.getElementById('usersEmails'),
    userDivContainer = document.getElementById('usersContainer'),
    maxUsers = userDivContainer.getAttribute('data-max'),
    addUserBtn = document.getElementById('addUser');

  checkUserNumber(maxUsers, addUserBtn);

  userDivContainer.addEventListener('click', function (event) {
    const target = event.target,
      groupCount = usersDiv.querySelectorAll(
        '.related-user__groupfield'
      ).length;

    if (target.matches('.related-user__remove-user')) {
      const group = target.parentNode;
      const userEmail = group.querySelector('input').value;

      if ('' !== userEmail) {
        Swal.fire({
          title: sprintf(
            // translators: %s: user email
            _x(
              'You are about to delete %s',
              'alert text in additional user',
              'vtx'
            ),
            userEmail
          ),
          text: _x(
            'Are you sure? This user will lose its access to the platform.',
            'alert text in additional user',
            'vtx'
          ),
          icon: 'warning',
          confirmButtonText: _x(
            'Delete',
            'alert text in additional user',
            'vtx'
          ),
          showDenyButton: true,
          denyButtonText: _x('Cancel', 'alert text in additional user', 'vtx'),
          showLoaderOnConfirm: true,
          preConfirm: () => {
            console.log('preconfirm:', userEmail);
            return apiFetch({
              path: 'cpnsss/v1/user/delete',
              method: 'POST',
              data: {
                email: userEmail,
              },
            })
              .then((response) => {
                if (!response) {
                  throw new Error(response.message);
                }
              })
              .catch((error) => {
                Swal.showValidationMessage(`Request failed: ${error.message}`);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          console.log('then result:', result);
          if (result.isConfirmed) {
            Swal.fire({
              title: sprintf(
                // translators: %s: user email
                _x(
                  'The user %s has been deleted.',
                  'alert text in additional user',
                  'vtx'
                ),
                userEmail
              ),
              text: _x(
                'Don\'t forget to save your changes by clicking on "save your users."',
                'alert text in additional user',
                'vtx'
              ),
              icon: 'success',
            });
            group.remove();
            checkUserNumber(maxUsers, addUserBtn);
          }
        });
      }
      if ('' === userEmail) {
        group.remove();
        checkUserNumber(maxUsers, addUserBtn);
      }
    }
    if (target.matches('#addUser')) {
      const group = document.querySelector('.related-user__groupfield');

      if (group) {
        const clone = group.cloneNode(true);
        if (maxUsers > 0 && groupCount < maxUsers) {
          usersDiv.append(clone);
          clone.querySelector('input').value = '';
          clone
            .querySelector('input')
            .classList.remove('related-user__input--filed');

          clone
            .querySelector('.related-user__remove-user')
            .classList.remove('hidden');
        }
        checkUserNumber(maxUsers, addUserBtn);
      } else {
        Swal.fire({
          title: _x(
            'Please save your deleted users prior to add new ones',
            'alert text in additional user',
            'vtx'
          ),
          icon: 'error',
        });
      }
    }
  });

  const modifiedInputs = new Set();
  const defaultValue = 'defaultValue';
  // store default values
  addEventListener('beforeinput', (evt) => {
    const target = evt.target;
    if (!(defaultValue in target || defaultValue in target.dataset)) {
      target.dataset[defaultValue] = (
        '' + (target.value || target.textContent)
      ).trim();
    }
  });
  // detect input modifications
  addEventListener('input', (evt) => {
    const target = evt.target;
    let original;
    if (defaultValue in target) {
      original = target[defaultValue];
    } else {
      original = target.dataset[defaultValue];
    }
    if (original !== ('' + (target.value || target.textContent)).trim()) {
      if (!modifiedInputs.has(target)) {
        modifiedInputs.add(target);
      }
    } else if (modifiedInputs.has(target)) {
      modifiedInputs.delete(target);
    }
  });
  // clear modified inputs upon form submission
  addEventListener('submit', () => {
    modifiedInputs.clear();
    // to prevent the warning from happening, it is advisable
    // that you clear your form controls back to their default
    // state with evt.target.reset() or form.reset() after submission
  });
  // warn before closing if any inputs are modified
  addEventListener('beforeunload', (evt) => {
    if (modifiedInputs.size) {
      const unsavedChangesWarning = _x(
        'Changes you made may not be saved.',
        'alert before changing page',
        'vtx'
      );
      evt.returnValue = unsavedChangesWarning;
      return unsavedChangesWarning;
    }
  });
}
